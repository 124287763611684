import React from "react";
import { Container, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
// import logo from "../../assets/images/logo.png";
import "./Footer.css";
import Section from "../Section/Section";
import Paragraph from "../Typography/Paragraph";
import useStyles from "./styles";
import { Facebook, MailOutline, Phone } from "@material-ui/icons";

export default function Footer() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Section bg="#343551" pd="3rem 1rem">
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={4}>
              <Paragraph color="#fff" size="600" bold="700" margin="1rem 0">Get Sales</Paragraph>
              <div className={classes.theFlex}>
                <MailOutline className={classes.theIcon} />
                <a href="mailto: sales@tagalliance.com.sg"> <Paragraph color="#fff">sales@tagalliance.com.sg</Paragraph></a>
              </div>
              <div className={classes.theFlex}>
                <Phone className={classes.theIcon} />
                <a href="tel:+65-6269-0472"> <Paragraph color="#fff">+65 6269 0472</Paragraph></a>
              </div>
              {/* <div className={classes.theFlex}>
                <Facebook className={classes.theIcon} />
                <Link> <Paragraph color="#fff">www.facebook.com/tagalliance</Paragraph></Link>
              </div> */}
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Paragraph color="#fff" size="600" bold="500" margin="1rem 0">Tag Alliance Pte Ltd</Paragraph>
              <Paragraph color="#fff">
                <a style={{ color: '#fff' }} href="https://goo.gl/maps/bsrGqiaxuB3p6Zw58"
                  target="_blank">
                  Sales Office
                </a>
              </Paragraph>
              <Paragraph color="#fff">
                <a style={{ color: '#fff' }} href="https://goo.gl/maps/bsrGqiaxuB3p6Zw58"
                  target="_blank">
                  Tag Alliance Pte Ltd
                </a>
              </Paragraph>
              <Paragraph color="#fff">
                <a style={{ color: '#fff' }} href="https://goo.gl/maps/bsrGqiaxuB3p6Zw58"
                  target="_blank">
                  222 Tagore Lane, TG Building
                </a>
              </Paragraph>
              <Paragraph color="#fff">
                <a style={{ color: '#fff' }} href="https://goo.gl/maps/bsrGqiaxuB3p6Zw58"
                  target="_blank">
                  #04-03, Singapore 787603
                </a>
              </Paragraph>
              <Paragraph color="#fff"><a style={{ color: '#fff' }} href="mailto:shawn@tagalliance.com.sg">Email: shawn@tagalliance.com.sg</a></Paragraph>
              
              {/* <Paragraph color="#fff"><a style={{ color: '#fff' }} href="tel:+65-6269-0472">Telephone: +65 62690472</a></Paragraph>
              <Paragraph color="#fff"><a style={{ color: '#fff' }} href="tel:+65-6269-0453">Facsimile: +65 62690453</a></Paragraph> */}
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Paragraph color="#fff" size="600" bold="500" margin="1rem 0">Tag Alliance (M) Sdn Bhd</Paragraph>
              <Paragraph color="#fff">
                <a style={{ color: '#fff' }} href="https://goo.gl/maps/D1yYcBHzmb5jDpRN9"
                  target="_blank">
                  LOT 5223, Baru 26, Jeram Batu,
                </a>
              </Paragraph>
              <Paragraph color="#fff">
                <a style={{ color: '#fff' }} href="https://goo.gl/maps/D1yYcBHzmb5jDpRN9"
                  target="_blank">
                  81500 Pekan Nanas,
                </a>
              </Paragraph>
              <Paragraph color="#fff">
                <a style={{ color: '#fff' }} href="https://goo.gl/maps/D1yYcBHzmb5jDpRN9"
                  target="_blank">
                  Pontian, Johor
                </a>
              </Paragraph>
              <Paragraph color="#fff"><a style={{ color: '#fff' }} href="tel:+07-699-1018">Telephone: 07 6991018</a></Paragraph>
              <Paragraph color="#fff"><a style={{ color: '#fff' }} href="tel:+07-699-1017">Facsimile: 07 6991017</a></Paragraph>
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section bg="#1c1d2f">
        <Paragraph color="#fff" center>Copyright © 2021 Tag Alliance</Paragraph>
      </Section>
    </React.Fragment >
  );
}
